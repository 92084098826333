<template>
  <div class="page">
    <div class="row">
      <div class="col d-flex align-center">
        <h1 class="font-lg">Услуги</h1>
      </div>
    </div>

    <div class="row">
      <div class="col d-flex align-center">
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab>Пакеты услуг</v-tab>
          <v-tab>Тарифы</v-tab>
          <v-tab>Тесты</v-tab>
        </v-tabs>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex align-center">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card>
              <v-card-text>Пакеты услуг</v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <v-card-text>Тарифы</v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <v-card-text>Тесты</v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: 0,
      tabs: [
        'web', 'shopping', 'videos', 'images', 'news',
      ],
    };
  },
};
</script>

<style>

</style>
